import { useContext, createContext, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store/index'
import { initialState } from '../store/Reducers/loginReducer'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { authCheck } from '@/store/asyncActions/auth'
import { unwrapResult } from '@reduxjs/toolkit'

type LoginStatus = 'loggedIn' | 'pending' | 'loggedOut';
const AuthStateContext = createContext(initialState.loginObj)

type Props = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  let loginObject = initialState.loginObj
  loginObject = useSelector((state: RootState) => state.loginReducer.loginObj)
  if (loginObject.auth_token === '') {
    const a = Cookies.get()
    if (a?.loginObj) {
      loginObject = JSON.parse(a.loginObj)?.data;
    }
  }

  const dispatch = useDispatch<AppDispatch>();

  const [loginStatus, setLoginStatus] = useState<LoginStatus>('pending');

  const loggedInUserId = useSelector((state: RootState) => state.loginReducer?.loggedInUserObj?.user_id)

  useEffect(() => {

    if (loggedInUserId > 0) {
      // User is already logged in
      setLoginStatus('loggedIn');
      return;
    }

    if (loggedInUserId === 0) {
      // let's check if user is logged in by fetching the user data
      dispatch(authCheck())
        .then((res) => {
          const { status } = unwrapResult(res);
          if (status === 'success') {
            setLoginStatus('loggedIn');
          } else {
            setLoginStatus('loggedOut');
          }
        }).
        catch((err) => {
          setLoginStatus('loggedOut');
        })
    }
  }, [loggedInUserId])
  return (
    <AuthStateContext.Provider value={{loginObject, loginStatus}}>
      {children}
    </AuthStateContext.Provider>
  )
}

export const useContextState = () => useContext(AuthStateContext)
