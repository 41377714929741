import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { BASE_API_AUTH } from '../components/Common/Constants';
import axios from 'axios';
import { RootState } from '.';
import questionConfig from '../components/Questions/config';
import { DEFAULT_LANGUAGE_CODE } from '../components/Questions/QuestionCreate/constant';
import {
  apiServiceInstance,
  apiServiceInstanceAddQuestion,
  apiServiceInstanceQuestionBuilder,
  axiosPostRequest,
} from './apiUtils';
import {
  answerObjectType,
  conditionObjectType,
  expressionLogicType,
  Media,
} from './Reducers/surveyReducer';

type loginObjProp = {
  email: string;
  password: string;
};

export const loginUser = createAsyncThunk(
  'loginUser',
  async (loginObj: loginObjProp) => {
    const res = await apiServiceInstance.post('/login/', loginObj);
    return res.data;
  },
);

type questionListRequestProp = {
  batchId: number;
  requestType?: string;
};

export const getQuestionList = createAsyncThunk(
  'getQuestionList',
  async ({ batchId, requestType }: questionListRequestProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/question/getall',
      {
        batchId,
        requestType,
      },
    );
    return res.data;
  },
);

export const addSection = async (payload: {
  batchId: number;
  orderId: number;
}) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/question/section',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const duplicateQuestion = async (payload: {
  batchId: number;
  orderId: number;
  questionIds: Array<number>;
  sectionId: number;
}) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/question/duplicate',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const reorderSection = async (payload: {
  batchId: number;
  orderId: number;
  sectionId: number;
  questionId?: number;
}) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/question/changsectioneorder',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const getSectionList = createAsyncThunk(
  'getSectionList',
  async ({ batchId }: questionListRequestProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/question/getsection',
      {
        batchId,
      },
    );
    return res.data;
  },
);

export const getIsValidId = async (batchId: number) => {
  const res = await apiServiceInstanceQuestionBuilder.post('/batch/getsingle', {
    batchId,
  });
  return res.data;
};

export const getSurveyDetails = createAsyncThunk(
  'getSurveyDetails',
  async (batchId: number) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/batch/getsingle',
      {
        batchId,
      },
    );
    return res.data;
  },
);

type questionDetailRequestProp = {
  batchId: number;
  pageType: string;
  questionId: number;
  sectionId?: number;
};

export const getQuestionDetails = createAsyncThunk(
  'getQuestionDetails',
  async ({
    batchId,
    pageType,
    questionId,
    sectionId,
  }: questionDetailRequestProp) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/question/getsingle',
      {
        batchId,
        pageType,
        questionId,
        sectionId,
      },
    );
    return {
      data: res.data,
      pageType,
    };
  },
);

type createQuestionType = {
  batchId: number;
  answerType: keyof typeof questionConfig;
  orderId: number;
  feqId: number;
  reOrder: boolean;
  sectionId: number;
};

export const createNewQuestion = createAsyncThunk(
  'createNewQuestion',
  async ({
    batchId,
    answerType,
    orderId,
    feqId,
    reOrder,
    sectionId,
  }: createQuestionType) => {
    const res = await apiServiceInstanceQuestionBuilder.post('/question/main', {
      batchId,
      answerType,
      orderId,
      feqId,
      reOrder,
      sectionId,
    });
    if (res?.data?.status !== 'success') {
      throw new Error('Failed to create question');
    }
    return {
      ...res.data,
      batchId,
      answerType,
      orderId,
      feqId,
      reOrder,
      sectionId,
    };
  },
);

type updateQuestionType = {
  batchId: number;
  questionId: number;
  feqId: number;
  orderId?: number;
  extraInfo?: null | object;
  attachmentType?: Media | '';
  url?: string;
  logicCondition?: conditionObjectType;
  questionObject?: answerObjectType;
  // option?: Array<string>
};

export const updateQuestionDetail = createAsyncThunk(
  'updateQuestionDetail',
  async (payload: updateQuestionType, { getState }) => {
    const state = getState() as RootState;
    const baseObject =
      state.surveyReducer.questionObject?.[payload?.feqId].questionObject || {};

    if (!state.surveyReducer?.questionObject?.[payload?.feqId]?.allFetched) {
      throw new Error('Question not fetched');
    }

    const newPayload = {
      ...payload,
      questionObject: {
        ...baseObject,
        ...(payload?.questionObject ?? {}),
      },
    };
    const res = await apiServiceInstanceAddQuestion.put(
      '/question/main',
      newPayload,
    );
    if (res.data.status === 'success') {
      return newPayload;
    }
    return {};
  },
);

type deleteQuestionType = {
  batchId: number;
  questionId: number;
  sectionId: number | null;
};

export const deleteQuestion = createAsyncThunk(
  'deleteQuestion',
  async (payload: deleteQuestionType) => {
    const res = await apiServiceInstanceQuestionBuilder.delete(
      '/question/main',
      {
        data: payload,
      },
    );
    if (res.data.status === 'success') {
      return payload;
    }
    return {};
  },
);

export type addQuestionTextType = {
  batchId: number;
  languageId: string;
  parentId: number;
  questionId: number;
  questionText: string;
  textType: string;
  cityId?: number;
  sectionId?: number | null;
  orderId: number;
};

export const addQuestionText = createAsyncThunk(
  'addQuestionText',
  async (payload: addQuestionTextType) => {
    const res = await apiServiceInstanceAddQuestion.post(
      '/question/text',
      payload,
    );
    if (res.data.status === 'success') {
      return {
        ...res.data,
        questionId: payload.questionId,
        textType: payload.textType,
      };
    }
    return {};
  },
);

export type messageTextType = {
  batchId: number;
  pageType: 'welcome' | 'terminate_page' | 'thank_you_page';
};

export const getMessageText = createAsyncThunk(
  'getMessageText/getQuestionDetails',
  async (data: messageTextType, { dispatch }) => {
    const { payload } = await dispatch(
      getQuestionDetails({ ...data, questionId: 0 }),
    );
    const { options } = payload;

    return {
      pageType: data.pageType,
      data:
        options?.find((d) => d.languageCode === DEFAULT_LANGUAGE_CODE)
          ?.option || null,
    };
  },
);

export type updateQuestionTextType = {
  batchId: number;
  languageId: string;
  parentId: number;
  questionText?: string;
  textId: number;
  questionId: number;
  cityId?: number;
  sectionId?: number | null;
  caption?: string;
  extraInfo?: null | string;
};

export const updateQuestionText = createAsyncThunk(
  'updateQuestionText',
  async (payload: updateQuestionTextType) => {
    const res = await apiServiceInstanceAddQuestion.put(
      '/question/text',
      payload,
    );
    if (res.data.status === 'success') {
      return {
        ...res.data,
        questionId: payload.questionId,
        textType: payload.textType,
      };
    }
    return {};
  },
);

export type deleteQuestionTextType = {
  batchId: number;
  textId: number;
  questionId?: number;
};

export const deleteQuestionText = createAsyncThunk(
  'deleteQuestionText',
  async (payload: deleteQuestionTextType) => {
    const newPayload = { ...payload };
    // delete newPayload.questionId;
    const res = await apiServiceInstanceAddQuestion.delete('/question/text', {
      data: newPayload,
    });
    if (res.data.status === 'success') {
      return {
        ...res.data,
        questionId: payload.questionId,
      };
    }
    return {};
  },
);

type changeQuestionOrderType = {
  batchId: number;
  questionId: number;
  orderId: number;
  feqId?: number;
  sectionId?: number;
};

export const changeQuestionOrder = createAsyncThunk(
  'changeQuestionOrder',
  async (payload: changeQuestionOrderType) => {
    const newPayload = { ...payload };
    delete newPayload.feqId;
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/question/changeorder',
      newPayload,
    );
    if (res.data.status === 'success') {
      return {
        ...res.data,
        questionId: payload.questionId,
        orderId: payload.orderId,
        feqId: payload.feqId,
      };
    }
    return {};
  },
);

const validatedLogicCondition = (condition, addAsQuestionObject = false) => {
  const newCondition = {
    list: [],
    status: false,
  };
  condition.list.forEach((cond) => {
    const tempList = {
      list: [],
      jump: addAsQuestionObject ? undefined : cond.jump,
      answer: addAsQuestionObject ? cond.jump : undefined,
    };
    cond.list.forEach((exp) => {
      if (exp?.logic) {
        tempList.list.push({
          ...exp,
        });
      } else if (exp.question && exp.condition && exp.value) {
        tempList.list.push({
          ...exp,
        });
      }
    });
    if (tempList.list.length > 0 && (tempList.jump || tempList.answer)) {
      newCondition.list.push(tempList);
      newCondition.status = true;
    }
  });
  return newCondition;
};

type updateJumpConditionType = {
  index: number;
  feqId: number;
  jump: number;
};

export const updateJumpCondition = createAsyncThunk(
  'updateJumpCondition',
  async (payload: updateJumpConditionType, { getState }) => {
    const state = getState();
    const addAsQuestionObject = payload.addAsQuestionObject;
    const conditions = addAsQuestionObject
      ? state.surveyReducer.questionObject[payload.feqId].questionObject
      : state.surveyReducer.questionObject[payload.feqId].condition;
    const newCondition = cloneDeep(conditions);
    newCondition.list[payload.index].jump = payload.jump;
    if (addAsQuestionObject) {
      newCondition.list[payload.index].answer = payload.jump;
    }
    const payloadCondition = validatedLogicCondition(
      newCondition,
      addAsQuestionObject,
    );
    let newPayload = {};
    if (addAsQuestionObject) {
      newPayload = {
        questionObject: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    } else {
      newPayload = {
        logicCondition: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    }
    const res = await apiServiceInstanceAddQuestion.put(
      '/question/main',
      newPayload,
    );
    if (res.data.status === 'success') {
      if (addAsQuestionObject) {
        return {
          feqId: payload.feqId,
          questionObject: newCondition,
        };
      }
      return {
        condition: newCondition,
        feqId: payload.feqId,
      };
    }
    return {};
  },
);

type getPreviewTokenType = {
  surveyId: number;
};

export const getPreviewToken = createAsyncThunk(
  'getPreviewToken',
  async (payload: getPreviewTokenType) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/responsetoken/generate',
      { batchId: payload.surveyId, time: 30, isPreview: true },
    );

    if (res.data.status === 'success') {
      return { previewToken: res.data.token };
    }
    return { previewToken: '' };
  },
);

export const invalidatePreviewToken = createAsyncThunk(
  'invalidatePreviewToken',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const previewToken = state.surveyReducer.previewToken;
    if (!previewToken) {
      return;
    }
    await axiosPostRequest({
      url: BASE_API_AUTH + 'logout/',
      config: {
        headers: { Authorization: `Bearer ${previewToken}` },
      },
      params: null,
    });
  },
);

type removeConditionType = {
  feqId: number;
  index: number;
};

export const removeCondition = createAsyncThunk(
  'removeCondition',
  async (payload: removeConditionType, { getState }) => {
    const state = getState();
    const addAsQuestionObject = payload.addAsQuestionObject;
    const conditions = addAsQuestionObject
      ? state.surveyReducer.questionObject[payload.feqId].questionObject
      : state.surveyReducer.questionObject[payload.feqId].condition;
    const newCondition = cloneDeep(conditions);
    newCondition.list.splice(payload.index, 1);
    if (newCondition.list.length === 0) {
      newCondition.status = false;
    }
    const payloadCondition = validatedLogicCondition(
      newCondition,
      addAsQuestionObject,
    );
    let newPayload = {};
    if (addAsQuestionObject) {
      newPayload = {
        questionObject: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    } else {
      newPayload = {
        logicCondition: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    }
    const res = await apiServiceInstanceQuestionBuilder.put(
      '/question/main',
      newPayload,
    );
    if (res.data.status === 'success') {
      if (addAsQuestionObject) {
        return {
          feqId: payload.feqId,
          questionObject: newCondition,
        };
      }
      return {
        condition: newCondition,
        feqId: payload.feqId,
      };
    }
    return {};
  },
);

type addExpressionType = {
  expressionIndex: number;
  conditionIndex: number;
  feqId: number;
  addAsQuestionObject: boolean;
  expressionObject: expressionLogicType;
};

export const addLogicExpression = createAsyncThunk(
  'addLogicExpression',
  async (payload: addExpressionType, { getState }) => {
    const state = getState();
    const addAsQuestionObject = payload.addAsQuestionObject;
    const conditions = addAsQuestionObject
      ? state.surveyReducer.questionObject[payload.feqId].questionObject
      : state.surveyReducer.questionObject[payload.feqId].condition;
    const newCondition = cloneDeep(conditions);
    newCondition.list[payload.conditionIndex].list[payload.expressionIndex] =
      payload.expressionObject;
    const payloadCondition = validatedLogicCondition(
      newCondition,
      addAsQuestionObject,
    );
    let newPayload = {};
    if (addAsQuestionObject) {
      newPayload = {
        questionObject: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    } else {
      newPayload = {
        logicCondition: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    }
    const res = await apiServiceInstanceAddQuestion.put(
      '/question/main',
      newPayload,
    );
    if (res.data.status === 'success') {
      if (addAsQuestionObject) {
        return {
          feqId: payload.feqId,
          questionObject: newCondition,
        };
      }
      return {
        condition: newCondition,
        feqId: payload.feqId,
      };
    }
    return {};
  },
);

type removeLogicExpressionType = {
  expressionIndex: number;
  conditionIndex: number;
  feqId: number;
  addAsQuestionObject: boolean;
};

export const removeLogicExpression = createAsyncThunk(
  'removeLogicExpression',
  async (payload: removeLogicExpressionType, { getState }) => {
    const state = getState();
    const addAsQuestionObject = payload.addAsQuestionObject;
    const conditions = addAsQuestionObject
      ? state.surveyReducer.questionObject[payload.feqId].questionObject
      : state.surveyReducer.questionObject[payload.feqId].condition;
    const newCondition = cloneDeep(conditions);
    const expressions = newCondition?.list[payload.conditionIndex]?.list || [];
    const expLength = expressions.length;
    // if index is 0 and leangth is greater than = 3
    // remove 1 & 2
    if (payload.expressionIndex === 0 && expLength < 3) {
      const conditionList = [...newCondition?.list];
      conditionList.splice(payload.conditionIndex, 1);
      newCondition.list = conditionList;
    } else if (payload.expressionIndex === 0 && expLength >= 3) {
      const newExp = [...expressions];
      newExp.splice(0, 2);
      newCondition.list[payload.conditionIndex].list = newExp;
    } else if (payload.expressionIndex > 1) {
      const newExp = [...expressions];
      newExp.splice(payload.expressionIndex - 1, 2);
      newCondition.list[payload.conditionIndex].list = newExp;
    }
    const payloadCondition = validatedLogicCondition(
      newCondition,
      addAsQuestionObject,
    );
    let newPayload = {};
    if (addAsQuestionObject) {
      newPayload = {
        questionObject: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    } else {
      newPayload = {
        logicCondition: payloadCondition,
        batchId: state.surveyReducer.surveyId,
        questionId: state.surveyReducer.questionObject[payload.feqId].id,
      };
    }
    const res = await apiServiceInstanceQuestionBuilder.put(
      '/question/main',
      newPayload,
    );
    if (res.data.status === 'success') {
      if (addAsQuestionObject) {
        return {
          feqId: payload.feqId,
          questionObject: newCondition,
        };
      }
      return {
        condition: newCondition,
        feqId: payload.feqId,
      };
    }
    return {};
  },
);

type uploadFileAndLinkProp = {
  batchId: number;
  questionId: number;
  file: File;
  textType: string;
  orderId: number;
};
const uploadFileAndLink = ({
  batchId,
  questionId,
  file,
  textType = 'url',
  orderId,
}: uploadFileAndLinkProp) => {
  return new Promise(async (resolve, reject) => {
    try {
      const signedURLResp = await apiServiceInstanceQuestionBuilder.post(
        '/file/getpresignedurl',
        {
          batchId,
          questionId,
          fileName: file.name,
          contentType: file.type,
        },
      );

      // const data = JSON.parse(signedURLResp.data);
      const data = signedURLResp.data;
      if (data.status !== 'success') {
        throw 'did not get signed url';
      }
      let url = data.value.substring(0, data.value.indexOf('?'));
      const fileHeader = {};
      fileHeader['Content-Type'] = file.type;
      await axios({
        method: 'PUT',
        data: file,
        url: data.value,
        headers: fileHeader,
      });
      // await apiServiceInstanceQuestionBuilder.post('/file/makepublic', {
      //   url,
      // });
      await apiServiceInstanceQuestionBuilder.post('/question/text', {
        batchId,
        languageId: DEFAULT_LANGUAGE_CODE,
        parentId: 0,
        orderId,
        questionId,
        questionText: url,
        textType,
      });
      resolve(data?.cloudFrontUrl);
    } catch (err) {
      reject(err);
    }
  });
};

type addImagesOptionsType = {
  batchId: number;
  questionId: number;
  feqId: number;
  startingIndex: number;
  files: Array<File>;
};

export const addImageOptions = createAsyncThunk(
  'addImageOptions',
  async (payload: addImagesOptionsType) => {
    try {
      const promiseAll = [];
      for (let i = 0; i < payload.files.length; i += 1) {
        promiseAll.push(
          uploadFileAndLink({
            orderId: i + payload.startingIndex,
            batchId: payload.batchId,
            questionId: payload.questionId,
            file: payload.files[i],
            textType: 'option',
          }),
        );
      }
      await Promise.all(promiseAll);
      const res = await apiServiceInstanceQuestionBuilder.post(
        '/question/getsingle',
        {
          batchId: payload.batchId,
          pageType: '',
          questionId: payload.questionId,
        },
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },
);

const uploadAttachmentMedia = ({ batchId, questionId, file }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const signedURLResp = await apiServiceInstanceQuestionBuilder.post(
        '/file/getpresignedurl',
        {
          batchId,
          questionId,
          fileName: file.name,
          contentType: file.type,
        },
      );

      const data = signedURLResp.data;
      if (data.status !== 'success') {
        throw 'did not get signed url';
      }
      let url = data.value.substring(0, data.value.indexOf('?'));
      const fileHeader = {};
      fileHeader['Content-Type'] = file.type;
      await axios({
        method: 'PUT',
        data: file,
        url: data.value,
        headers: fileHeader,
      });
      // await apiServiceInstanceQuestionBuilder.post('/file/makepublic', {
      //   url,
      // });

      resolve(data.cloudFrontUrl);
    } catch (err) {
      reject(err);
    }
  });
};

type addMediaAttachmentType = {
  batchId: number;
  questionId: number;
  feqId: number;
  file: File;
  attachmentType: string;
};

export const addMediaAttachment = createAsyncThunk(
  'addMediaAttachment',
  async (payload: addMediaAttachmentType) => {
    try {
      const url = await uploadAttachmentMedia({
        batchId: payload.batchId,
        questionId: payload.questionId,
        file: payload.file,
      });
      const pyLoad = {
        batchId: payload.batchId,
        questionId: payload.questionId,
        feqId: payload.feqId,
        attachmentType: payload.attachmentType,
        url: url,
      };
      const res = await apiServiceInstanceQuestionBuilder.put(
        '/question/main',
        pyLoad,
      );
      if (res.data.status === 'success') {
        return pyLoad;
      }
      return {};
    } catch (err) {
      return {};
    }
  },
);

type removeMediaAttachmentType = {
  batchId: number;
  questionId: number;
  feqId: number;
};

export const removeMediaAttachment = createAsyncThunk(
  'removeMediaAttachment',
  async (payload: removeMediaAttachmentType) => {
    try {
      const pyLoad = {
        batchId: payload.batchId,
        questionId: payload.questionId,
        feqId: payload.feqId,
        attachmentType: '',
        attachmentFile: null,
        url: '',
      };
      const res = await apiServiceInstanceQuestionBuilder.put(
        '/question/main',
        pyLoad,
      );
      if (res.data.status === 'success') {
        return pyLoad;
      }
      return {};
    } catch (err) {
      return {};
    }
  },
);

export const getTemplateCategories = createAsyncThunk(
  'getTemplateCategories',
  async () => {
    const res = await apiServiceInstanceQuestionBuilder.post('/getproject/');
    return res.data;
  },
);

export const getTemplates = createAsyncThunk(
  'getTemplates',
  async (payload: any) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/getbatch/',
      payload,
    );
    return res.data;
  },
);

export const getTemplateQuestions = createAsyncThunk(
  'getTemplateQuestions',
  async (payload: any) => {
    const res = await apiServiceInstanceQuestionBuilder.post(
      '/getquestion/',
      payload,
    );
    return res.data;
  },
);

export const addLongFormatQuestion = async (addLongFormatQuestionObj: any) => {
  const res = await apiServiceInstanceQuestionBuilder.post(
    '/question/addlongformate',
    addLongFormatQuestionObj,
  );
  return res;
};
