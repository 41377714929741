import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiServiceInstanceDashboard,
  apiServiceInstanceDashboardWithWaitTime,
  apiServiceInstancePlayground,
  apiServiceInstancePlaygroundWithWaitTime,
} from '../apiUtils';
import {
  activateThemeLongFormatType,
  addNoteObjProp,
  addResponseObjProp,
  deleteLongFormatThemeVerbatimObjType,
  deleteNoteObjPropType,
  deleteThemeLongFormatType,
  editLongFormatThemeSentimentType,
  editNoteObjTypeProp,
  editSpeakerNameObjProp,
  getPlaygroundChatObjProp,
  getPlaygroundResponseByIdObjProp,
  getPlaygroundResponseObjProp,
  getThemeListType,
  getVideoLevelThemeListLongFormatType,
  hideSpeakerObjProp,
  PanelObjProp,
  postEditSpeakerObjType,
  postEditTranscriptObjType,
  postLongFormatThemeObjType,
  postLongFormatThemeVerbatimObjType,
  postRevertTranscriptObjType,
} from '../Reducers/Types';

export type treeDataPayloadType = {
  questionId: number;
  batchId: number;
  theme: string;
};

export type videoDataPayloadType = {
  questionId: number;
  batchId: number;
  videoId: number;
};
export const getThemeListLongFormat = createAsyncThunk(
  'getThemeListLongFormat',
  async (getThemeListLongFormat: getThemeListType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/long_format/getPrimaryThemes/',
      getThemeListLongFormat,
    );
    return res;
  },
);

export const getVideoLevelThemeListLongFormat = createAsyncThunk(
  'getVideoLevelThemeListLongFormat',
  async (getThemeListLongFormat: getVideoLevelThemeListLongFormatType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/themeLongForamt/gelist/',
      getThemeListLongFormat,
    );
    return res;
  },
);

export const activateLongFormatTheme = async (
  activateThemeObj: activateThemeLongFormatType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/themeLongForamt/activate/',
    activateThemeObj,
  );
  return res;
};

export const deleteLongFormatTheme = async (
  deleteThemeObj: deleteThemeLongFormatType,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/themeLongForamt/gelist/',
    {
      data: deleteThemeObj,
    },
  );
  return res;
};

export const postLongFormatTheme = async (
  postLongFormatThemeObj: postLongFormatThemeObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/themeLongForamt/',
    postLongFormatThemeObj,
  );
  return res;
};

export const postLongFormatThemeVerbatim = async (
  postLongFormatThemeVerbatimObj: postLongFormatThemeVerbatimObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/ThemeVerbatimLongFormat/verbatim/',
    postLongFormatThemeVerbatimObj,
  );
  return res;
};

export const postEditSpeaker = async (
  postEditSpeakerObj: postEditSpeakerObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/changeSpeaker/',
    postEditSpeakerObj,
  );
  return res;
};

export const postEditTranscript = async (
  postEditTranscriptObj: postEditTranscriptObjType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/getTranscript/',
    postEditTranscriptObj,
  );
  return res;
};

export const postRevertTranscript = async (
  postRevertTranscriptObj: postRevertTranscriptObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/revertTranscript/',
    postRevertTranscriptObj,
  );
  return res;
};

export const deleteLongFormatThemeVerbatim = async (
  postLongFormatThemeVerbatimObj: deleteLongFormatThemeVerbatimObjType,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/ThemeVerbatimLongFormat/verbatim/',
    {
      data: postLongFormatThemeVerbatimObj,
    },
  );
  return res;
};

export const editLongFormatThemeSentiment = async (
  editThemeSentimentObj: editLongFormatThemeSentimentType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/themeLongForamt/renameGroup/',
    editThemeSentimentObj,
  );
  return res;
};

export const getTreeData = async (payload: treeDataPayloadType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getGraphCluster/',
    payload,
  );
  return res.data;
};

export const getLongFormatUploadedList = async (payload: getThemeListType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getUploadedList/',
    payload,
  );
  return res.data.value;
};

export const getLongformatAnalysisVideoList = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getAllVideos/',
    payload,
  );
  if (res.data.status === 'success') {
    return {
      list: res.data.value,
      totalCount: res.data.totalCount,
      offset: res.data.offset,
      clusteringPending: res.data.clusteringPending,
      haveExistingCluster: res.data.haveExistingCluster,
    };
  }
  return {
    list: [],
    totalCount: 0,
    offset: 0,
    clusteringPending: false,
    haveExistingCluster: false,
  };
};

export const getLongformatVideoData = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getSingleVideo/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const downloadLongFormatTranscript = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/downlaodTranscript/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.url;
  }
  return null;
};

export const getLongformatWordList = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getWordTimeStamp/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const hideSpeaker = async (hideSpeakerObj: hideSpeakerObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/hideSpeakerAnalysis/',
    hideSpeakerObj,
  );
  return res;
};

export const editSpeakerName = async (
  editSpeakerNameObj: editSpeakerNameObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/renameSpeaker/',
    editSpeakerNameObj,
  );
  return res;
};

export const addNote = async (addNoteObj: addNoteObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/notes/',
    addNoteObj,
  );
  return res;
};

export const editNotes = async (editNoteObj: editNoteObjTypeProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/notes/',
    editNoteObj,
  );
  return res;
};

export const deleteNote = async (deleteNoteObj: deleteNoteObjPropType) => {
  const res = await apiServiceInstanceDashboard.delete('/long_format/notes/', {
    data: deleteNoteObj,
  });
  return res;
};

export const getLongformatTranscript = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getTranscript/',
    payload,
  );
  if (res.status === 200) {
    return res.data;
  }
  return null;
};

type clusterServiceType = {
  batchId: number;
  questionId: number;
  isRegenerate: boolean;
};

export const clusterService = createAsyncThunk(
  'invokeClusterService',
  async (payload: clusterServiceType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/long_format/cluster_service/',
      payload,
    );
    return res;
  },
);

export const addResponse = async (addResponseObj: addResponseObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/addResponse/',
    addResponseObj,
  );
  return res;
};

export const downloadClusterAnalysis = async (downloadClusterAnalysisObj: {
  batchId: number;
}) => {
  const res = await apiServiceInstanceDashboard.post(
    '/contentAnalysis/download/',
    downloadClusterAnalysisObj,
  );
  return res;
};

export const editResponse = async (editResponseObj: addResponseObjProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/addResponse/',
    editResponseObj,
  );
  return res;
};

export const getPlaygroundChatList = async (
  getPlaygroundChatListObj: PanelObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getchatlist/',
    getPlaygroundChatListObj,
  );
  return res;
};

export const getPlaygroundChat = async (
  getPlaygroundChatObj: getPlaygroundChatObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getchatresponse/',
    getPlaygroundChatObj,
  );
  return res;
};

export const getPlaygroundResponse = async (
  getPlaygroundResponseObj: getPlaygroundResponseObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getlongformat/',
    getPlaygroundResponseObj,
  );
  return res;
};

export const getPlaygroundNewResponse = async (
  getPlaygroundResponseUsersObj: getPlaygroundResponseObjProp,
) => {
  try {
    const res = await apiServiceInstancePlaygroundWithWaitTime.post(
      '/playground/getQueryResponse/',
      getPlaygroundResponseUsersObj,
    );
    return res.data.value ?? {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const getPlaygroundResponseById = async (
  getPlaygroundResponseByIdObj: getPlaygroundResponseByIdObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getlongformatResponse/',
    getPlaygroundResponseByIdObj,
  );
  return res.data.response ?? [];
};

export const getShortFormPlaygroundNewResponse = async (
  getPlaygroundResponseByIdObj: getPlaygroundResponseObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getShortFormQueryResponse/',
    getPlaygroundResponseByIdObj,
  );
  return res.data?.value ?? [];
};

export const getShortPlaygroundChat = async (
  getPlaygroundChatObj: getPlaygroundChatObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getShortChatResponse/',
    getPlaygroundChatObj,
  );
  return res.data?.value ?? [];
};

export const addMultipleQuery = async (getPlaygroundResponseByIdObj: {
  batchId: number;
  queryArray: Array<string>;
  addToContentAnalysis: boolean;
}) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/addMultipleQuery/',
    getPlaygroundResponseByIdObj,
  );
  return res;
};

export const getPlaygroundResponseSummary = async (
  getPlaygroundResponseSummaryObj: getPlaygroundChatObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getlongformatSummary/',
    getPlaygroundResponseSummaryObj,
  );
  return res.data ?? [];
};

export const deleteLongFormatPlaygroundChat = async (deleteThemeObj: any) => {
  const res = await apiServiceInstancePlayground.delete(
    '/playground/getchatresponse/',
    {
      data: deleteThemeObj,
    },
  );
  return res;
};

export const postPinChat = async (postPinChatObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/pinChat/',
    postPinChatObj,
  );
  return res.data ?? [];
};

export const toggleContentAnalysis = async (toggleContentAnalysisObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/addToContentAnalysis/',
    toggleContentAnalysisObj,
  );
  return res.data ?? [];
};

export const getPlaygroundChatProof = async (
  getPlaygroundChatProofObj: any,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getchatproof/',
    getPlaygroundChatProofObj,
  );
  return res.data ?? [];
};

export const reRunQuery = async (reRunQueryObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/reRunLongFormatQuery/',
    reRunQueryObj,
  );
  return res.data?.message ?? [];
};

export const retriggerVerbatim = async (reRunQueryObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/trigger_retry_verbatim/',
    reRunQueryObj,
  );
  return res.data?.message ?? [];
};
