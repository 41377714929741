// Question type constants
export const VIDEO_QUESTION = 'video';
export const AUDIO_QUESTION = 'audio';
export const TEXT_QUESTION = 'text';
export const TEXT_ANALYSIS_QUESTION = 'AVT-Text';
export const NUMBER_QUESTION = 'number';
export const AVT_QUESTION = 'AVT';
export const SINGLE_TEXT_QUESTION = 'singleTextSelect';
export const SINGLE_IMAGE_QUESTION = 'singleImageSelect';
export const MULTIPLE_TEXT_QUESTION = 'multiTextSelect';
export const MULTIPLE_IMAGE_QUESTION = 'multipleImageSelect';
export const DROPDOWN_QUESTION = 'dropDown';
export const AGE_QUESTION = 'age';
export const GENDER_QUESTION = 'gender';
export const CITY_QUESTION = 'city';
export const NAME_QUESTION = 'name';
export const EMAIL_QUESTION = 'email';
export const INFO_QUESTION = 'info';
export const PHONE_NUMBER_QUESTION = 'phone';
export const MATRIX_QUESTION = 'matrix';
export const LIKERT_QUESTION = 'likert';
export const EMOTICONS_QUESTION = 'emotionIcons';
export const RATING_QUESTION = 'ratingSelect';
export const NPS_QUESTION = 'nps';
export const DATE_ONLY_QUESTION = 'date';
export const TIME_ONLY_QUESTION = 'time';
export const DATE_TIME_QUESTION = 'datetime';
export const FILE_UPLOAD_QUESTION = 'file';
export const SEC_GRID_QUESTION = 'secGrid';
export const EMOTION_QUESTION = 'emotion';
export const NCCS_QUESTION = 'nccsGrid';
export const LSM_QUESTION = 'lsmGrid';
export const IMAGE_CAPTURE_QUESTION = 'imageCapture';
export const LOCATION_QUESTION = 'location';
export const RANDOMISE_MEDIA_QUESTION = 'randomiseMedia';
export const TERMINATE_SCREEN = 'terminate';
export const WELCOME_SCREEN = 'welcome';
export const THANKYOU_SCREEN = 'thank_you_page';
export const ANSWER_GROUPING_QUESTION = 'answerGroupLabel';
export const QUADRANT_CHART_QUESTION = 'quadrantChart';
export const POLICY_QUESTION = 'policy';
export const RANKING_QUESTION = 'ranking';
export const SECTION_ROUTING_QUESTION = 'sectionRouting';
export const IMAGE_COMPARISION_QUESTION = 'imageEdit';

// Category constants
export const BASICS = 'Basics - Non-AI Analysis';
export const OPEN_END = 'Open End - AI Analysis';
export const SELECTIONS = 'Choices';
export const CONTACT_INFORMATION = 'Contact Information';
export const RATING = 'Rating';
export const TIME_SCHEDULE = 'Time and Scheduling';
export const FILE_UPLOAD = 'File Upload';
export const MORE = 'More';
export const ADDITIONAL = 'Additional';

// Question Media Constants
export const UPLOAD_MEDIA = 'upload';
export const RECORD_AUDIO = 'recordAudio';
export const RECORD_VIDEO = 'recordVideo';

// Prop Types
export type IconProps = {
  color: string;
  size?: number;
};
export type AnswerArrayProps = {
  value?: string;
  file?: string;
};
export type EditContentProps = {
  type?: string;
  feqId: number;
  questionId?: number;
};

// Conditions name
export const HAS_ANSWERED = 'has answered';

// None option
export const NONE_OPTION = 'None of the above';
