import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Sidebar from '../Sidebar';
import { SettingTabs as Tabs } from '../Sidebar/Tabs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BackIcon } from '../Common/Icons';

type PagesProps = {
  children: JSX.Element;
};

const PagesLayout = ({ children }: PagesProps) => {
  const router = useRouter();
  const { pathname } = router;

  const currentPageIndex = Tabs.findIndex((tab) => tab.id === pathname);

  return (
    <div className="flex h-[100%] overflow-y-auto">
      <Sidebar tabId={pathname as string} surveyId={12} Tabs={Tabs} />
      <Fade className="w-full">
        <div className="flex w-11/12 flex-1 flex-col h-screen">
          <div className="box-border w-full h-14 p-3 pb-4 flex flex-row justify-between items-center border-b border-b-indigo-100 shadow-xs">
            <div className="flex flex-row items-center">
              <Link href="/home">
                <div className="cursor-pointer pl-1">
                  <BackIcon color="#242424" width={11} height={14} />
                </div>
              </Link>
              <span className="pl-3 heading-4">
                {Tabs[currentPageIndex].text}
              </span>
            </div>
          </div>
          {children}
        </div>
      </Fade>
    </div>
  );
};

export default PagesLayout;
