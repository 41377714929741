import { getIsValidId } from '@/store/asyncActions';
import { useRouter } from 'next/router';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useContextState } from '../../context/auth';
import Loader from '../Common/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/index';

type Props = {
  children: JSX.Element;
  accessType: 'authenticated' | 'anonymous' | null;
};

function extractRouteAndParams(url: string) {
  const urlObj = new URL(url, 'http://example.com'); // The base URL is needed for the URL constructor
  let subRoute = urlObj.pathname;
  const queryParams = Object.fromEntries(new URLSearchParams(urlObj.search));

  if (subRoute.endsWith('/')) {
    subRoute = subRoute.slice(0, -1);
  }

  return {
    subRoute,
    queryParams,
  };
}

export function AuthGuard({ accessType, children }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isValidId, setIsValidId] = useState(true);
  const [canRender, setCanRender] = useState(false);
  const [isChangingRoute, setIsChangingRoute] = useState(false);
  const [onlyTabChanged, setOnlyTabChanged] = useState(false);

  const contextState = useContextState();
  const status = contextState?.loginStatus;
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { id, tabId } = router.query;

  const previousStateRef = useRef({
    subRoute: router.pathname,
    tabId: (tabId as string) || '',
    id: (id as string) || '',
  });

  const handleRouteChangeStart = useCallback((newUrl: string, ...v: any) => {
    let { subRoute, queryParams } = extractRouteAndParams(newUrl);
    const prevState = previousStateRef.current;
    if (
      (subRoute == prevState.subRoute &&
        queryParams.id == prevState.id &&
        queryParams.tabId != prevState.tabId) ||
      (subRoute == prevState.subRoute &&
        queryParams.id == prevState.id &&
        queryParams.tabId == prevState.tabId)
    ) {
      setOnlyTabChanged(true);
    } else {
      setIsChangingRoute(true);
      setCanRender(false);
      setOnlyTabChanged(false);
    }

    previousStateRef.current = {
      subRoute,
      tabId: queryParams.tabId,
      id: queryParams.id,
    };
  }, []);

  const handleRouteChangeComplete = useCallback(() => {
    setIsChangingRoute(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events, handleRouteChangeStart, handleRouteChangeComplete]);

  const isValidIdCheck = useCallback(
    async (id: string) => {
      try {
        const res = await getIsValidId(id);
        if (res.status !== 'success') {
          setIsValidId(true);
          setCanRender(true);
          router.push('/page_not_found');
        }
      } catch (err) {
        console.error('Error validating ID:', err);
        setIsValidId(true);
        setCanRender(true);
        router.push('/page_not_found');
      }
    },
    [router],
  );

  const checkAuthentication = useCallback(async () => {
    setIsLoading(true);
    setCanRender(false);

    if (accessType === 'authenticated') {
      if (status === 'loggedOut') {
        router.replace('/sign_in');
        return;
      }
      if (id) {
        await isValidIdCheck(id as string);
      }
    } else if (accessType === 'anonymous' && status === 'loggedIn') {
      router.replace('/home');
      return;
    }

    setIsLoading(false);
    setCanRender(true);
  }, [accessType, status, id, isValidIdCheck, router]);

  useEffect(() => {
    if (router.isReady && !isChangingRoute && !onlyTabChanged) {
      checkAuthentication();
    }
  }, [router.isReady, isChangingRoute, checkAuthentication]);

  useEffect(() => {
    if (tabId && canRender) {
      // Handle tabId changes without triggering a full re-render
      console.log(`Tab changed to: ${tabId}`);
    }
  }, [tabId, canRender]);

  if (isLoading || !isValidId || !canRender || isChangingRoute) {
    return <Loader />;
  }

  return <>{children}</>;
}
