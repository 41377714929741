import React from 'react';

const SettingsIcon = ({ color = '#242424', width, height }) => {
  return (
    <svg
      width={width || '15'}
      height={height || '17'}
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.19646 4L8.93361 2.09714C9.05792 1.77497 9.27664 1.49785 9.56112 1.30208C9.8456 1.10631 10.1826 1.00102 10.5279 1H11.9336C12.2789 1.00102 12.6159 1.10631 12.9004 1.30208C13.1849 1.49785 13.4036 1.77497 13.5279 2.09714L14.265 4L16.7679 5.44L18.7907 5.13143C19.1276 5.08571 19.4704 5.14116 19.7756 5.29073C20.0809 5.44029 20.3348 5.67723 20.505 5.97143L21.1908 7.17143C21.3665 7.47031 21.4474 7.81545 21.4229 8.16129C21.3984 8.50713 21.2697 8.83742 21.0536 9.10857L19.8022 10.7029V13.5829L21.0879 15.1771C21.304 15.4483 21.4327 15.7786 21.4572 16.1244C21.4817 16.4703 21.4007 16.8154 21.225 17.1143L20.5393 18.3143C20.3691 18.6085 20.1152 18.8454 19.8099 18.995C19.5047 19.1446 19.1619 19.2 18.825 19.1543L16.8022 18.8457L14.2993 20.2857L13.5622 22.1886C13.4379 22.5107 13.2191 22.7879 12.9347 22.9836C12.6502 23.1794 12.3132 23.2847 11.9679 23.2857H10.5279C10.1826 23.2847 9.8456 23.1794 9.56112 22.9836C9.27664 22.7879 9.05792 22.5107 8.93361 22.1886L8.19646 20.2857L5.69361 18.8457L3.67075 19.1543C3.33392 19.2 2.9911 19.1446 2.68586 18.995C2.38062 18.8454 2.12673 18.6085 1.95646 18.3143L1.27075 17.1143C1.09504 16.8154 1.01408 16.4703 1.03857 16.1244C1.06305 15.7786 1.19182 15.4483 1.40789 15.1771L2.65932 13.5829V10.7029L1.37361 9.10857C1.15754 8.83742 1.02877 8.50713 1.00428 8.16129C0.979797 7.81545 1.06075 7.47031 1.23646 7.17143L1.92218 5.97143C2.09245 5.67723 2.34633 5.44029 2.65158 5.29073C2.95682 5.14116 3.29964 5.08571 3.63646 5.13143L5.65932 5.44L8.19646 4ZM7.80218 12.1429C7.80218 12.821 8.00326 13.4838 8.38 14.0477C8.75673 14.6115 9.2922 15.0509 9.91869 15.3104C10.5452 15.5699 11.2346 15.6378 11.8996 15.5055C12.5647 15.3733 13.1756 15.0467 13.6551 14.5672C14.1346 14.0877 14.4612 13.4768 14.5934 12.8117C14.7257 12.1467 14.6578 11.4573 14.3983 10.8308C14.1388 10.2043 13.6994 9.66884 13.1356 9.2921C12.5717 8.91537 11.9089 8.71429 11.2308 8.71429C10.3214 8.71429 9.44937 9.07551 8.80638 9.71849C8.1634 10.3615 7.80218 11.2335 7.80218 12.1429V12.1429Z"
        stroke={color}
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
