export const ENV = process.env.NEXT_PUBLIC_ENV;

export const BASE_API_AUTH = process.env.NEXT_PUBLIC_AUTH_SERVICE;

export const BASE_API_QUESTION_BUILDER =
  process.env.NEXT_PUBLIC_QUESTION_BUILDER;

export const BASE_API_DASHBOARD = process.env.NEXT_PUBLIC_ANALYSIS_SERVICE;

export const BASE_API_PAYMENTS = process.env.NEXT_PUBLIC_PAYMENT_SERVICE;

export const BASE_API_PLAYGROUND = process.env.NEXT_PUBLIC_PLAYGROUND_SERVICE;

export const passwordValidations = [
  'Should have atleast 10 letters',
  'Should have a number',
  'Should have an uppercase character',
  'Should have a special character',
  'Should have a lowercase character',
];

export const sortByData = [
  { id: 'createdDate', text: 'Created Date' },
  { id: 'title', text: 'Title' },
  { id: 'respondent', text: 'Responses' },
  { id: 'updatedDate', text: 'Updated Date' },
];

export const orderByData = [
  { id: 'desc', text: 'Descending' },
  { id: 'asc', text: 'Ascending' },
];

export const statusData = [
  { id: 'done', text: 'Done' },
  { id: 'rejected', text: 'Rejected' },
];

export const languagesList = [
  { id: 'en', title: 'English (United States)' },
  { id: 'de', title: 'German (Germany)' },
  { id: 'zh', title: 'Chinese, Mandarin' },
  { id: 'cs', title: 'Czech (Czech Republic)' },
  { id: 'da', title: 'Danish (Denmark)' },
  { id: 'fr', title: 'French (France)' },
  { id: 'it', title: 'Italian (Italy)' },
  { id: 'ja', title: 'Japanese (Japan)' },
  { id: 'pt', title: 'Portuguese (Brazil)' },
  { id: 'es', title: 'Spanish (Spain)' },
  { id: 'tr', title: 'Turkish (Turkey)' },
  { id: 'ms', title: 'Malay (Malaysia)' },
  { id: 'id', title: 'bahasa (Indonesian)' },
  { id: 'ben', title: 'Bengali (India)' },
  { id: 'gu', title: 'Gujarat (India)' },
  { id: 'mr', title: 'Marathi (India)' },
  { id: 'hi', title: 'Hindi (India)' },
  { id: 'te', title: 'Telugu (India)' },
  { id: 'kn', title: 'Kannada (India)' },
  { id: 'ml', title: 'Malayalam (India)' },
  { id: 'mr', title: 'Marathi (India)' },
  { id: 'ta', title: 'Tamil (India)' },
];

export const TRANSCRIPT_LANGUAGES = [
  {
    id: 'af',
    text: 'afrikaans',
  },
  {
    id: 'sq',
    text: 'albanian',
  },
  {
    id: 'am',
    text: 'amharic',
  },
  {
    id: 'ar',
    text: 'arabic',
  },
  {
    id: 'hy',
    text: 'armenian',
  },
  {
    id: 'as',
    text: 'assamese',
  },
  {
    id: 'az',
    text: 'azerbaijani',
  },
  {
    id: 'ba',
    text: 'bashkir',
  },
  {
    id: 'eu',
    text: 'basque',
  },
  {
    id: 'be',
    text: 'belarusian',
  },
  {
    id: 'bn',
    text: 'bengali',
  },
  {
    id: 'bs',
    text: 'bosnian',
  },
  {
    id: 'br',
    text: 'breton',
  },
  {
    id: 'bg',
    text: 'bulgarian',
  },
  {
    id: 'ca',
    text: 'catalan',
  },
  {
    id: 'zh',
    text: 'chinese',
  },
  {
    id: 'hr',
    text: 'croatian',
  },
  {
    id: 'cs',
    text: 'czech',
  },
  {
    id: 'da',
    text: 'danish',
  },
  {
    id: 'nl',
    text: 'dutch',
  },
  {
    id: 'en',
    text: 'english',
  },
  {
    id: 'et',
    text: 'estonian',
  },
  {
    id: 'fo',
    text: 'faroese',
  },
  {
    id: 'fi',
    text: 'finnish',
  },
  {
    id: 'fr',
    text: 'french',
  },
  {
    id: 'gl',
    text: 'galician',
  },
  {
    id: 'ka',
    text: 'georgian',
  },
  {
    id: 'de',
    text: 'german',
  },
  {
    id: 'el',
    text: 'greek',
  },
  {
    id: 'gu',
    text: 'gujarati',
  },
  {
    id: 'ht',
    text: 'haitian creole',
  },
  {
    id: 'ha',
    text: 'hausa',
  },
  {
    id: 'haw',
    text: 'hawaiian',
  },
  {
    id: 'iw',
    text: 'hebrew',
  },
  {
    id: 'hi',
    text: 'hindi',
  },
  {
    id: 'hu',
    text: 'hungarian',
  },
  {
    id: 'is',
    text: 'icelandic',
  },
  {
    id: 'id',
    text: 'indonesian',
  },
  {
    id: 'it',
    text: 'italian',
  },
  {
    id: 'ja',
    text: 'japanese',
  },
  {
    id: 'jw',
    text: 'javanese',
  },
  {
    id: 'kn',
    text: 'kannada',
  },
  {
    id: 'kk',
    text: 'kazakh',
  },
  {
    id: 'km',
    text: 'khmer',
  },
  {
    id: 'ko',
    text: 'korean',
  },
  {
    id: 'lo',
    text: 'lao',
  },
  {
    id: 'la',
    text: 'latin',
  },
  {
    id: 'lv',
    text: 'latvian',
  },
  {
    id: 'ln',
    text: 'lingala',
  },
  {
    id: 'lt',
    text: 'lithuanian',
  },
  {
    id: 'lb',
    text: 'luxembourgish',
  },
  {
    id: 'mk',
    text: 'macedonian',
  },
  {
    id: 'mg',
    text: 'malagasy',
  },
  {
    id: 'ms',
    text: 'malay',
  },
  {
    id: 'ml',
    text: 'malayalam',
  },
  {
    id: 'mt',
    text: 'maltese',
  },
  {
    id: 'mi',
    text: 'maori',
  },
  {
    id: 'mr',
    text: 'marathi',
  },
  {
    id: null,
    text: 'mixed',
  },
  {
    id: 'mn',
    text: 'mongolian',
  },
  {
    id: 'my',
    text: 'myanmar',
  },
  {
    id: 'ne',
    text: 'nepali',
  },
  {
    id: 'no',
    text: 'norwegian',
  },
  {
    id: 'nn',
    text: 'nynorsk',
  },
  {
    id: 'oc',
    text: 'occitan',
  },
  {
    id: 'ps',
    text: 'pashto',
  },
  {
    id: 'fa',
    text: 'persian',
  },
  {
    id: 'pl',
    text: 'polish',
  },
  {
    id: 'pt',
    text: 'portuguese',
  },
  {
    id: 'pa',
    text: 'punjabi',
  },
  {
    id: 'ro',
    text: 'romanian',
  },
  {
    id: 'ru',
    text: 'russian',
  },
  {
    id: 'sa',
    text: 'sanskrit',
  },
  {
    id: 'sr',
    text: 'serbian',
  },
  {
    id: 'sn',
    text: 'shona',
  },
  {
    id: 'sd',
    text: 'sindhi',
  },
  {
    id: 'si',
    text: 'sinhala',
  },
  {
    id: 'sk',
    text: 'slovak',
  },
  {
    id: 'sl',
    text: 'slovenian',
  },
  {
    id: 'so',
    text: 'somali',
  },
  {
    id: 'es',
    text: 'spanish',
  },
  {
    id: 'su',
    text: 'sundanese',
  },
  {
    id: 'sw',
    text: 'swahili',
  },
  {
    id: 'sv',
    text: 'swedish',
  },
  {
    id: 'tl',
    text: 'tagalog',
  },
  {
    id: 'tg',
    text: 'tajik',
  },
  {
    id: 'ta',
    text: 'tamil',
  },
  {
    id: 'tt',
    text: 'tatar',
  },
  {
    id: 'te',
    text: 'telugu',
  },
  {
    id: 'th',
    text: 'thai',
  },
  {
    id: 'bo',
    text: 'tibetan',
  },
  {
    id: 'tr',
    text: 'turkish',
  },
  {
    id: 'tk',
    text: 'turkmen',
  },
  {
    id: 'uk',
    text: 'ukrainian',
  },
  {
    id: 'ur',
    text: 'urdu',
  },
  {
    id: 'uz',
    text: 'uzbek',
  },
  {
    id: 'vi',
    text: 'vietnamese',
  },
  {
    id: 'cy',
    text: 'welsh',
  },
  {
    id: 'yi',
    text: 'yiddish',
  },
  {
    id: 'yo',
    text: 'yoruba',
  },
];

export const ENGLISH_LANGUAGES = [
  { id: 'en', text: 'American English' },
  { id: 'en_uk', text: 'British English' },
  { id: 'en_au', text: 'Australian English' },
];

export const TRANSLATE_LANGUAGES = [
  { id: 'es', text: 'Spanish' },
  { id: 'fr', text: 'French' },
  { id: 'de', text: 'German' },
  { id: 'it', text: 'Italian' },
  { id: 'pt', text: 'Portuguese' },
  { id: 'nl', text: 'Dutch' },
  { id: 'hi', text: 'Hindi' },
  { id: 'ja', text: 'Japanese' },
  { id: 'zh', text: 'Chinese' },
  { id: 'fi', text: 'Finnish' },
  { id: 'ko', text: 'Korean' },
  { id: 'pl', text: 'Polish' },
  { id: 'ru', text: 'Russian' },
  { id: 'tr', text: 'Turkish' },
  { id: 'vi', text: 'Vietnamese' },
];

export const AnalysislanguagesList = [
  { id: 'en', text: 'English (United States)' },
  { id: 'manual', text: 'Manual Transcript' },
];

export const PAGINATION_LIMIT = 10;

export const roleData = [
  { id: 'Brand', text: 'Brand' },
  { id: 'Transcript_vendor', text: 'Transcript Vendor' },
  { id: 'Data_vendor_with_transcript', text: 'Data Vendor With Transcript' },
  {
    id: 'Data_vendor_with_out_transcript ',
    text: 'Data Vendor Without Transcript',
  },
  { id: 'Data_qc_vendor', text: 'Data QC vendor' },
  { id: 'Owner', text: 'Owner' },

  { id: 'Research_associate', text: 'Research Associate' },
  { id: 'Client', text: 'Client' },
  { id: 'Client_with_out_personal_info', text: 'Client Without Personal Info' },
  { id: 'Data_viewer_only_approved', text: 'Data viewer only approved' },
  { id: 'Data_viewer', text: 'Data viewer' },
];

export const operationDropDown = [
  { id: 'OP-Reject', text: 'Reject' },
  { id: 'OP-Done', text: 'Approve' },
];

export const enumerateDropDown = [
  { id: 'Done', text: 'Approve' },
  { id: 'Reject', text: 'Reject' },
];

export const operationDropDownHeader = [
  { id: 'OP-Reject', text: 'Reject' },
  { id: 'Pending', text: 'Pending' },
  { id: 'OP-Done', text: 'Approve' },
];
export const enumerateDropDownHeader = [
  { id: 'Done', text: 'Approve' },
  { id: 'Pending', text: 'Pending' },
  { id: 'Reject', text: 'Reject' },
];

export const rejectReasonDropDown = [
  { id: '0', text: 'Profile SEC mismatch - wrong SEC selected' },
  { id: '1', text: 'Profile SEC mismatch - CWE Education code' },
  { id: '2', text: 'Profile SEC mismatch - CWE Occupation code' },
  { id: '3', text: 'Profile Age mismatch' },
  { id: '4', text: 'Profile Location mismatch' },
  { id: '5', text: 'Profile Gender mismatch' },
  { id: '6', text: 'Profile - Respondent not correct' },
  { id: '7', text: 'No video captured' },
  { id: '8', text: 'Video - junk upload' },
  { id: '9', text: 'Video -  No voice' },
  { id: '10', text: 'Video - No face' },
  { id: '11', text: 'Video - irrelevant video background' },
  { id: '12', text: 'Video - same video for all question' },
  { id: '13', text: 'Audio - no audio' },
  { id: '14', text: 'Audio - audio not good / clear' },
  { id: '15', text: 'Audio - audio with irrelevant answer' },
  { id: '16', text: 'Audio - audio with multiple voice' },
  { id: '17', text: 'Audio - audio with lot of noise' },
  { id: '18', text: 'Audio - audio with lot of disturbances' },
  { id: '19', text: 'Irrelevant answer' },
  { id: '20', text: 'Bad answer' },
  { id: '21', text: 'Response is scripted' },
  { id: '22', text: 'Response is prompted' },
];

export const returnFilterTypeReportElement = (answerType: any) => {
  switch (answerType) {
    case 'matrix':
    case 'summary':
    case 'theme':
    case 'theme_multiple':
    case 'sentiment':
      return answerType;

    default:
      return 'question';
  }
};
export const SEPARATOR = '#SEP#';

// this will keep the slider function smooth
export const MOD = 10 * 100 * 100;

export const SIGN_UP_USER_TYPES = [
  { id: 'all', text: 'All' },
  { id: 'EY', text: 'EY' },
  { id: 'USA', text: 'USA' },
];
