import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiServiceInstanceDashboard } from '../apiUtils';

type elementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
  reportElementId: number;
};

type putElementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
  reportElementId: number;
  title: string;
};

type reorderElementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
  orderId: number;
};

type updatePage = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
  title?: string;
  titleStyle?: object;
  notes?: string;
  notesStyle?: object;
  description?: string;
  descriptionStyle?: object;
  layoutType?: string;
  layoutStyle?: object;
};

export const reportsGetList = createAsyncThunk(
  'reportsGetList',
  async (payload: { batchId: number | string; projectId: number | string }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/report/getlist/',
      payload,
    );
    return res.data?.value || [];
  },
);

export const reportsGetElements = async (payload: elementType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/report/getelements/',
    payload,
  );
  return res?.data;
};

export const reportsGetPageList = createAsyncThunk(
  'reportsGetPageList',
  async (payload: {
    batchId: number | string;
    projectId: number | string;
    reportId: number | string;
  }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/report/getpagelist/',
      payload,
    );
    return res.data?.value || [];
  },
);

type createNewReportType = {
  batchId: number | string;
  projectId: number | string;
  title: string;
  description: string;
};

export const createNewReport = createAsyncThunk(
  'createNewReport',
  async (payload: createNewReportType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/report/main/',
      payload,
    );
    return res.data || {};
  },
);
export type setLayoutType = {
  projectId: number;
  batchId: number;
  reportId: number;
  orderId: number;
  notes: string;
  notesStyle: object;
  layoutType: string;
  layoutStyle: object;
  reportPageId?: number;
  elements?: [];
};

export const setLayout = async (payload: setLayoutType) => {
  const res = await apiServiceInstanceDashboard.post('/report/page/', payload);
  return Number(res.data.id);
};

export type setElementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  orderId: number;
  reportPageId: number;
  filterObject: object;
  filterId?: number;
  filterType: string;
  title: string;
  titleStyle: object;
  notes: string;
  notesStyle: object;
  elementType: string;
  elementStyle: object;
  layoutType: string;
  layoutStyle: object;
  matrixKey?: string;
};

export type updateElementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
  reportElementId: number;
  filterObject: object;
  filterType: string;
  title: string;
  titleStyle: object;
  description: string;
  descriptionStyle: object;
  notes: string;
  notesStyle: object;
  elementType: string;
  elementStyle: object;
  layoutType: string;
  layoutStyle: object;
  matrixKey?: string;
};

export const setElement = createAsyncThunk(
  'setElements',
  async (payload: setElementType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/report/elements/',
      payload,
    );
    return res.data || {};
  },
);
export const updateElement = createAsyncThunk(
  'updateElements',
  async (payload: updateElementType) => {
    const res = await apiServiceInstanceDashboard.put(
      '/report/elements/',
      payload,
    );
    return res.data || {};
  },
);

export type getElementType = {
  projectId: number;
  batchId: number;
  reportId: number;
  reportPageId: number;
};

export type reportType = {
  projectId: number;
  batchId: number;
  reportId: number;
};

export const deleteElement = async (deleteElementObj: elementType) => {
  const res = await apiServiceInstanceDashboard.delete('/report/elements/', {
    data: deleteElementObj,
  });
  return res;
};

export const deletePage = async (deletePageObj: getElementType) => {
  const res = await apiServiceInstanceDashboard.delete('/report/page/', {
    data: deletePageObj,
  });
  return res;
};

export const deleteReport = async (deleteReportObj: reportType) => {
  const res = await apiServiceInstanceDashboard.delete('/report/main/', {
    data: deleteReportObj,
  });
  return res;
};

export const updatePage = async (payload: updatePage) => {
  const res = await apiServiceInstanceDashboard.put('/report/page/', payload);
  return res.data;
};

export const putElement = async (payload: putElementType) => {
  const res = await apiServiceInstanceDashboard.put(
    '/report/elements/',
    payload,
  );
  return res.data;
};

export const reorderPage = async (payload: reorderElementType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/report/reorderpage/',
    payload,
  );
  return res.data;
};
