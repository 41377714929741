import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { EnumerateLogo } from '../Dashboard/Icons';
import ProfileMenu from '../Common/ProfileMenu';
import {
  longFormatPermissionType,
  surveyPermissionsObjType,
  usagePermissionsObjType,
} from '@/store/Reducers/Types';
import { TabType } from './Tabs';
import useCobranding from '@/hooks/useCobranding';
import Colab from '../Common/Colab';

export type SidebarProps = {
  tabId: string;
  surveyId: number;
  permissions?:
    | surveyPermissionsObjType
    | longFormatPermissionType
    | usagePermissionsObjType;
  Tabs: TabType[];
};

const Sidebar: FunctionComponent<SidebarProps> = ({
  Tabs,
  tabId,
  surveyId,
  permissions,
}) => {
  const selected = 'rounded bg-link-blue stroke-white fill-white color-white';

  const { isCobranding, brandLogo } = useCobranding();

  return (
    <div className="box-border flex w-[72px] flex-col justify-between items-center px-10 border-r border-b-indigo-100">
      <div className="flex flex-col items-center p-2">
        <Link href="/home">
          <div
            className="box-content w-[68px] h-11 border-b border-b-enum-border-color"
            style={{
              paddingBottom: '0.2rem',
              paddingLeft: '1.5rem',
            }}
          >
            <EnumerateLogo withName={false} />
          </div>
        </Link>
        <div className="mt-2">
          {Tabs.map(
            (tab) =>
              (!permissions ||
                permissions[tab.permission] === true ||
                tab.id === 'video_analysis' ||
                tab.id === 'playground' ||
                tab.id === 'content-analysis' ||
                tab.id == 'my-usage' ||
                ((tab.id === 'clients-data' ||
                  tab.id === 'client-management') &&
                  permissions['admin'])) && (
                <Link
                  key={tab.id}
                  href={tab.route(tab.id || '', `${surveyId || ''}`)}
                >
                  <div
                    id={tab.id}
                    className="flex justify-centers flex-col items-center"
                  >
                    <div
                      className={`box-content flex justify-center mt-2 items-center w-7 h-5 p-3 object-contain stroke-enum-logo ${
                        tabId === tab.id
                          ? selected
                          : 'transition duration-500 hover:scale-110 cursor-pointer hover:bg-slate-100'
                      }`}
                    >
                      {tab.Icon(tabId === tab.id ? '#FFFFFF' : '#242424')}
                    </div>
                    <span className="w-full flex px-2 justify-center mt-0 tags-font text-center text-wrap text-primary-black">
                      {tab.text}
                    </span>
                  </div>
                </Link>
              ),
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-between space-y-4">
        {isCobranding && <Colab />}
        <ProfileMenu />
      </div>
    </div>
  );
};

export default Sidebar;
