import { createSlice } from '@reduxjs/toolkit';
import {
  reelsDetails,
  reelsGetList,
  updateReel,
  checkJobStatus,
  getUserThemes,
  addUserTheme,
  deleteUserTheme,
  updateUserTheme,
  getReviewUserThemes,
  createUserThemeGroup,
  updateUserThemeGroup,
  getUserPromptInstruct,
  createUserPromptInstruct,
  updateUserPromptInstruct,
  getThemeOperationStatus,
} from '../asyncActions/aiAnalysis';
import { toast } from 'react-toastify';

export type QuestionAnalysisStatus = {
  answerType: string;
  feqId: number;
  id: number;
  orderId: number;
  sectionId: number;
  sectionOrderId: number;
  questionText: string;
  summaryJobStatus: string;
  themeJobStatus: string;
  lastThemeGeneratedAt: string | null;
};

export type ReelInfo = {
  thumbnail?: string;
  reelInfoId: number;
  clipTitle: string;
  reelId: number;
  videoId: number;
  startTime: number;
  endTime: number;
  videoUrl: string;
  orderId: number;
  clipUrl?: string;
  videoTime?: number;
  answer_type?: string;
  clipGroup?: string;
  thumbnails?: Array<any>;
  id?: number;
};

export interface ReelDownloadHistory {
  createdAt: number;
  url: string;
}

export interface ReelDetails {
  reelId: number;
  batchId: number;
  title: string;
  description: string;
  downloadStatus: 'start' | 'complete' | null;
  reelInfo?: Array<ReelInfo>;
}

export interface UserThemes {
  id: number;
  theme: string;
  groupId: number;
  description: string | null;
  example: string | null;
  tag: string;
  color: string;
  isActive: boolean;
}

export interface ReviewUserThemes {
  editTheme: UserThemes | null;
  userThemes: {
    id: number;
    themes: UserThemes[];
  };
  nerThemes: {
    id: number;
    themes: UserThemes[];
  };
  userPrompt: {
    id: number;
    themes: UserThemes[];
    prompt: string;
  };
}

interface IAIAnalysisData {
  surveyId: number;
  questionId: number;
  totalUsers: number;
  reels: Array<ReelDetails>;
  isLoading: boolean;
  aiAnalysisStatus: {
    loading: boolean;
    questions: QuestionAnalysisStatus[];
    haveUnprocessedVideos: boolean;
  };
  groupId: number;
  groupStatus: 'completed' | 'themes_found' | 'in_progress' | 'pending' | null;
  userInstructId: number;
  userThemes: UserThemes[];
  userThemesTagId: number | null;
  userPromptInstruct: {
    id: number;
    tagId: number;
    prompt: string;
    themes: UserThemes[];
    isExecuted: boolean;
    status: 'completed' | 'in_progress' | 'pending';
  };
  themeFlowStatus: {
    themeJobStatus: 'completed' | 'in_progress' | 'pending';
    instructStatus: 'completed' | 'in_progress' | 'pending' | 'theme_found';
    nerStatus: 'completed' | 'in_progress' | 'pending' | 'themes_found';
  };
  reviewThemes: ReviewUserThemes;
}

const initialState: IAIAnalysisData = {
  totalUsers: 0,
  surveyId: -1,
  questionId: -1,
  reels: [],
  isLoading: false,
  aiAnalysisStatus: {
    loading: false,
    questions: [],
    haveUnprocessedVideos: false,
  },
  groupId: -1,
  groupStatus: null,
  userInstructId: -1,
  userThemes: [],
  userThemesTagId: null,
  themeFlowStatus: {
    themeJobStatus: 'pending',
    instructStatus: 'pending',
    nerStatus: 'pending',
  },
  userPromptInstruct: {
    id: -1,
    tagId: -1,
    prompt: '',
    themes: [],
    isExecuted: false,
    status: 'pending',
  },
  reviewThemes: {
    editTheme: null,
    userThemes: {
      id: -1,
      themes: [],
    },
    nerThemes: {
      id: -1,
      themes: [],
    },
    userPrompt: {
      id: -1,
      themes: [],
      prompt: '',
    },
  },
};

export const AIAnalysisSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectQuestion: (state, action) => {
      state.surveyId = action.payload.surveyId;
      state.questionId = action.payload.questionId;
    },
    cleanup: (state) => {
      state = { ...initialState };
    },
    reelDetailsCleanup: (state) => {
      state.reels = [];
    },
    updateReelInfoClipsOrder: (state, action) => {
      const { reelId, orderedReelInfoArr } = action.payload;
      state.reels = state.reels.map((r) => {
        if (r.reelId != reelId) return r;
        return { ...r, reelInfo: orderedReelInfoArr };
      });
    },
    resetGroupId: (state) => {
      state.groupId = -1;
    },
    resetUserInstructId: (state) => {
      state.userInstructId = -1;
    },
    editReviewTheme: (state, action) => {
      state.reviewThemes.editTheme = action.payload;
    },
    resetEditReviewTheme: (state) => {
      state.reviewThemes.editTheme = null;
    },
    setTotalUsers: (state, action) => {
      state.totalUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkJobStatus.pending, (state) => {
        state.aiAnalysisStatus.loading = true;
      })
      .addCase(checkJobStatus.fulfilled, (state, action) => {
        const { payload } = action;
        state.aiAnalysisStatus.haveUnprocessedVideos =
          payload?.haveUnprocessedVideos || false;
        state.aiAnalysisStatus.questions = payload?.questions || [];
        state.aiAnalysisStatus.loading = false;
      })
      .addCase(reelsGetList.fulfilled, (state, action) => {
        state.reels = action.payload;
      })
      .addCase(reelsDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reelsDetails.fulfilled, (state, action) => {
        const { reelId, value } = action.payload;

        if (reelId != 0) {
          // just getting a specific reel
          const updatedReel = value[0] as ReelDetails;
          state.reels = state.reels.map((r: ReelDetails) => {
            if (updatedReel.reelId == r.reelId) {
              return {
                ...updatedReel,
                reelInfo: updatedReel.reelInfo?.sort(
                  (a, b) => a.orderId - b.orderId,
                ),
              } as ReelDetails;
            }
            return r;
          });
        } else {
          // getting all the reels
          state.reels = value.map((r: ReelDetails) => {
            return {
              ...r,
              reelInfo: r.reelInfo?.sort((a, b) => a.orderId - b.orderId),
            };
          });
        }
        state.isLoading = false;
      })
      .addCase(reelsDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReel.fulfilled, (state, action) => {
        const { payload } = action;
        state.reels = state.reels.map((currentReel) => {
          if (currentReel.reelId != payload.reelId) return currentReel;
          return { ...currentReel, ...payload };
        });
      })
      .addCase(createUserThemeGroup.fulfilled, (state, action) => {
        state.groupId = action.payload;
      })
      .addCase(updateUserThemeGroup.fulfilled, (state, action) => {
        const { tagId } = action.payload;
        state.userThemesTagId = tagId;
      })
      .addCase(getUserThemes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserThemes.fulfilled, (state, action) => {
        state.userThemes = action.payload.userThemes;
        state.groupStatus = action.payload.status;
        state.userThemesTagId = action.payload.tagId;
        state.isLoading = false;
      })
      .addCase(addUserTheme.fulfilled, (state, action) => {
        // toast.success('Theme added successfully');
      })
      .addCase(updateUserTheme.fulfilled, (state, action) => {
        const showToasts = action.payload.showToast;
        if (!showToasts) return;
        toast.success('Theme updated successfully');
      })
      .addCase(deleteUserTheme.fulfilled, (state, action) => {
        // toast.success('Theme deleted successfully');
      })
      .addCase(createUserPromptInstruct.fulfilled, (state, action) => {
        state.userInstructId = action.payload;
      })
      .addCase(updateUserPromptInstruct.fulfilled, (state, action) => {
        if (action.payload.tagId)
          state.userPromptInstruct.tagId = action.payload.tagId;
      })
      .addCase(getUserPromptInstruct.fulfilled, (state, action) => {
        state.userPromptInstruct = action.payload;
      })
      .addCase(getThemeOperationStatus.fulfilled, (state, action) => {
        const themeFlowStatus = action.payload;
        // convert all null values to pending
        for (const key in themeFlowStatus) {
          if (themeFlowStatus[key] == null) {
            themeFlowStatus[key] = 'pending';
          }
        }
        state.themeFlowStatus = themeFlowStatus;
      })
      .addCase(getReviewUserThemes.fulfilled, (state, action) => {
        state.reviewThemes = action.payload;
      });
  },
});

export const { actions: dashboardActions } = AIAnalysisSlice;
export default AIAnalysisSlice.reducer;
