import {
  EditTabLogo,
  ThemeTabLogo,
  PreviewTabLogo,
  UserManagementTabLogo,
  UserShareLogo,
  UploadTabLogo,
  DashboardTabLogo,
  PlaygroundTabLogo,
  ContentAnalysisTabLogo,
  ReportingLogo,
  ClientManagementLogo,
  MyUsageLogo,
  ClientsUsageLogo,
} from './icon';
import {
  FormLogo,
  AnalysisLogo,
  AIBuilder,
  Quota,
  TransDash,
  ReelLogo,
  ReportDash,
} from '../Dashboard/Icons';
import { BillingIcon } from '../Common/Icons';
import SettingsIcon from '../Survey/SettingsIcon';
import { SidebarProps } from '.';

export type TabType = {
  id: string;
  text: string;
  Icon: any;
  permission: string;
  route: (tabId: string, surveyId: string) => string;
};

export const dynamicRouteSurvey = (tabId: string, surveyId: string) =>
  `/survey?tabId=${tabId}&id=${surveyId}`;

export const dynamicRouteDashboard = (tabId: string, surveyId: string) =>
  `/dashboard?tabId=${tabId}&id=${surveyId}`;

export const dynamicRouteLongformat = (tabId: string, surveyId: string) =>
  `/longformat?tabId=${tabId}&id=${surveyId}`;

export const dynamicRouteReporting = (tabId: string, surveyId: string) =>
  `/templates?tabId=${tabId}&id=${surveyId}`;

export const dynamicRouteUsage = (tabId: string) => `/usage?tabId=${tabId}`;

export const SurveyTabs = [
  {
    id: 'edit',
    text: 'Edit',
    Icon: (color: string) => <EditTabLogo color={color} />,
    permission: 'show_edit_survey',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteSurvey(tabId, surveyId),
  },
  {
    id: 'theme',
    text: 'Design',
    Icon: (color: string) => <ThemeTabLogo color={color} />,
    permission: 'show_theme',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteSurvey(tabId, surveyId),
  },
  {
    id: 'preview',
    text: 'Preview',
    Icon: (color: string) => <PreviewTabLogo color={color} />,
    permission: 'show_preview',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteSurvey(tabId, surveyId),
  },
  {
    id: 'user',
    text: 'Share',
    Icon: (color: string) => <UserShareLogo color={color} />,
    permission: 'show_share_survey',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteSurvey(tabId, surveyId),
  },
  {
    id: 'setting',
    text: 'Team',
    Icon: (color: string) => <UserManagementTabLogo color={color} />,
    permission: 'show_user_management',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteSurvey(tabId, surveyId),
  },
];

export const LongFormatTabs: TabType[] = [
  {
    id: 'upload',
    text: 'Upload',
    Icon: (color: string) => <UploadTabLogo color={color} />,
    permission: 'upload',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'analysis',
    text: 'Media Analysis',
    Icon: (color: string) => <AnalysisLogo color={color} />,
    permission: 'analysis',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'playground',
    text: 'Playground',
    Icon: (color: string) => <PlaygroundTabLogo color={color} />,
    permission: 'playground',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'content-analysis',
    text: 'Content Analysis',
    Icon: (color: string) => <ContentAnalysisTabLogo color={color} />,
    permission: 'excel_upload',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'dashboard',
    text: 'Universal Themes',
    Icon: (color: string) => <DashboardTabLogo color={color} />,
    permission: 'dashboard',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'reels',
    text: 'Reel Player',
    Icon: (color: string) => <ReelLogo color={color} />,
    permission: 'reels',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
  {
    id: 'setting',
    text: 'Team',
    Icon: (color: string) => <UserManagementTabLogo color={color} />,
    permission: 'show_user_management',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteLongformat(tabId, surveyId),
  },
];

export const UsageTabs: TabType[] = [
  {
    id: 'my-usage',
    text: 'My Usage',
    Icon: (color: string) => <MyUsageLogo color={color} />,
    permission: '',
    route: (tabId: string) => dynamicRouteUsage(tabId),
  },
  {
    id: 'clients-data',
    text: 'Org Usage',
    Icon: (color: string) => <ClientsUsageLogo color={color} />,
    permission: '',
    route: (tabId: string) => dynamicRouteUsage(tabId),
  },
  {
    id: 'client-management',
    text: 'Org Management',
    Icon: (color: string) => <ClientManagementLogo color={color} />,
    permission: '',
    route: (tabId: string) => dynamicRouteUsage(tabId),
  },
];

export const ReportingTabs: TabType[] = [
  {
    id: 'report',
    text: 'Report',
    Icon: (color: string) => <ReportingLogo color={color} />,
    permission: 'report',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteReporting(tabId, surveyId),
  },
  {
    id: 'share',
    text: 'Share',
    Icon: (color: string) => <UserShareLogo color={color} />,
    permission: 'share',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteReporting(tabId, surveyId),
  },
];

export const FORM_TAB = 'form';
export const TRANSCRIPTION_TAB = 'transcription-dashboard';
export const ANALYSIS_TAB = 'analysis';
export const REPORTING_TAB = 'reporting';
export const REELS_TAB = 'reels';
export const QUOTA_TAB = 'dashboard';
export const UPLOAD_TAB = 'upload';
export const DashboardTabs = [
  {
    id: UPLOAD_TAB,
    text: 'Upload',
    Icon: (color: string) => <UploadTabLogo color={color} />,
    permission: 'excel_upload',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
  {
    id: FORM_TAB,
    text: 'Data',
    Icon: (color: string) => <FormLogo color={color} />,
    permission: 'show_form',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
  // {
  //   id: 'ai-builder',
  //   text: 'AI Builder',
  //   Icon: (color: string) => <AIBuilder color={color} />,
  //   permission: 'show_ai_analysis',
  //   route: (tabId: string, surveyId: string) =>
  //     dynamicRouteDashboard(tabId, surveyId),
  // },
  {
    id: TRANSCRIPTION_TAB,
    text: 'Transcription',
    Icon: (color: string) => <TransDash color={color} />,
    permission: 'show_transcript',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
  {
    id: ANALYSIS_TAB,
    text: 'Analysis',
    Icon: (color: string) => <AnalysisLogo color={color} />,
    permission: 'show_analysis',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
  {
    id: REELS_TAB,
    text: 'Reels Player',
    Icon: (color: string) => <ReelLogo color={color} />,
    permission: 'show_ai_analysis',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
  {
    id: REPORTING_TAB,
    text: 'Reporting',
    Icon: (color: string) => <ReportDash color={color} />,
    permission: 'show_reports',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },

  {
    id: QUOTA_TAB,
    text: 'Quota',
    Icon: (color: string) => <Quota color={color} />,
    permission: 'show_quota_dashboard',
    route: (tabId: string, surveyId: string) =>
      dynamicRouteDashboard(tabId, surveyId),
  },
];

export const SettingTabs = [
  {
    id: '/settings',
    text: 'Settings',
    permission: '',
    Icon: (color: string) => (
      <SettingsIcon width={20} height={20} color={color} />
    ),
    route: () => 'settings',
  },
  // {
  //   id: '/billing',
  //   text: 'Billings',
  //   permission: '',
  //   Icon: (color: string) => (
  //     <BillingIcon width={20} height={20} color={color} />
  //   ),
  //   route: () => 'billing',
  // },
];
