export const EDIT_QUESTION = 'edit';
export const MEDIA_QUESTION = 'media';
export const LOGIC_QUESTION = 'logic';
export const LANGUAGE_QUESTION = 'language';
export const COPY_QUESTION = 'copy';

export const DEFAULT_LANGUAGE_CODE = 'en';

// type for tab content
export type TabContentProps = {
  feqId: number;
  sectionId?: number;
  questionId?: number;
  answerType?: string;
};
